///////////////////////////////////
// Navigation
///////////////////////////////////

// ******************************
// Hamburger icon
// ******************************

let hamburgerIcon = $('.hamburger');
let menuIcon = $('.js-menu');

let dropDown = $('.navigation__link--drop-down > a');
let subList = $('.navigation__sub-list, .sub-menu');
// Hamburger icon active
hamburgerIcon.on('click', function(e){
    e.preventDefault();
    hamburgerIcon.toggleClass('hamburger--active');
    subList.slideUp();
    dropDown.removeClass('navigation__link--drop-down-active');
    dropDown.removeClass('navigation__link--active');
})


// ******************************
// Navigation drop down (accordion)
// ******************************

// Accordion
dropDown.on('click', function(e) {
    e.preventDefault();
    
    subList.slideToggle(500);
    $(this).parent().toggleClass('navigation__link--drop-down-active');
    $(this).parent().toggleClass('navigation__link--active');
});

// ******************************
// Navigation open/close
// ******************************

let navigation = $('.navigation');
let navigationBtn = $('.navigation .btn--close');

// Open navigation
hamburgerIcon.on('click', function(e){

    navigation.toggleClass('navigation--open');
    $('body').toggleClass('body--hiddne');
    $('.header').toggleClass('header--navigation-active');
});

menuIcon.on('click', function(e){

    navigation.toggleClass('navigation--open');
    $('body').toggleClass('body--hiddne');
    $('.header').toggleClass('header--navigation-active');

    hamburgerIcon.toggleClass('hamburger--active');
    subList.slideUp();
    dropDown.removeClass('navigation__link--drop-down-active');
    dropDown.removeClass('navigation__link--active');
});

// Close navigation
navigationBtn.on('click', function(e){
    e.preventDefault();

    subList.slideUp();
    hamburgerIcon.addClass('hamburger--active');
    navigation.addClass('navigation--open');
    $('body').addClass('body--hiddne');
    $('.header').addClass('header--navigation-active');
});
